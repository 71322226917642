<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { Link } from '@inertiajs/vue3'
import { BlogPost as IBlogPost } from '@interfaces/blog-post.interface'
import BlogPost from '@/Components/UI/BlogPost.vue'
import { ArrowRightIcon } from '@heroicons/vue/20/solid'

defineProps<{
    posts: Array<IBlogPost>
}>()

const { t } = useI18n()
</script>

<template>
    <section class="pt-16 pb-24 bg-white sm:pb-32">
        <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div class="sm:flex sm:items-baseline sm:justify-between">
                <h2 class="font-medium text-grayscale-900 text-h5">{{ t('pages.home.latest-articles.latest-in-journal') }}</h2>
                <Link
                    :href="route('blog.index')"
                    class="hidden font-medium text-p2 text-primary-600 hover:text-primary-800 sm:inline-flex sm:items-center group"
                >
                {{ t('pages.home.latest-articles.discover-more') }}
                <ArrowRightIcon class="w-4 h-4 ml-1 transition-transform duration-300 transform group-hover:translate-x-0.5" />
                </Link>
            </div>

            <div class="grid grid-cols-1 mx-auto mt-6 gap-y-20 gap-x-8 md:grid-cols-3">
                <BlogPost
                    v-for="post in posts"
                    :key="post.id"
                    :post="post"
                />
            </div>
        </div>
    </section>
</template>
